import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app">
      <div className="app--container">
        <h1 className="app--title">WIP.</h1>
        <p>Coming Soon.</p>
        <p>Creative developer at <a className="link--148" href="https://148.fr" title="Agence 148 Website" target="_blank" rel="noopener noreferrer">148 Agency</a>.</p>
        <p>Find me on <a className="link--linkedin" href="https://www.linkedin.com/in/huu-tai-le-b873b1109/" title="LinkedIn Profil" target="_blank" rel="noopener noreferrer">LinkedIn</a>.</p>
      </div>
    </div>
  );
}

export default App;
